<template>
  <div id="centerRight2">
    <div class="bg-color-black">
      <span class="fs-xl text">本周产品销售渠道分析</span>
      <div class="d-flex flex-column body-box" v-if="isRresh">
        <dv-capsule-chart class="dv-cap-chart" :config="config" />
        <centerRight :cdata="cdata" />
      </div>
    </div>
  </div>
</template>

<script>
import centerRight from './centerRight/index.vue'
import { getArea } from '../../../request/api'

export default {
  components: { centerRight },
  data () {
    return {
      config: {
        data: []
      },
      cdata: {},
      timing: null,
      isRresh: true
    }
  },
  methods: {
    async changeNumber () {
      const res = await getArea()
      if (res.status !== 200 || res.data.code !== '1000') this.$message.error(res.data.msg)
      this.cdata = res.data.data
      this.config.data = res.data.data.seriesData
      this.update()
    },
    changeTiming () {
      this.changeNumber()
      // 十分钟一刷新
      this.timing = setInterval(() => {
        this.changeNumber()
      }, 600000)
    },
    update () {
      this.isRresh = false
      this.$nextTick(() => {
        this.isRresh = true
      })
    }
  },
  mounted () {
    this.changeTiming()
  },
  // 销毁前调用
  beforeDestroy () {
    clearInterval(this.timing)
    this.timing = null
  }
}
</script>

<style lang="less" scoped>
.fs-xl {
  width: 100%;
  font-size: 8px;
}
.d-flex {
  display: flex;
}
.flex-column {
  flex-direction: column;
}
.centerRight2 {
  height: 410px;
  width: 340px;
  padding: 5px;
  border-radius: 5px;
  .bg-color-black {
    padding: 5px;
    height: 410px;
    width: 340px;
    border-radius: 10px;
    .text {
      color: #c3cbde;
    }
    .body-box {
      border-radius: 10px;
      overflow: hidden;
      .dv-cap-chart {
        width: 100%;
        height: 160px;
      }
    }
  }
}
</style>