<template>
  <div class="centerRight1">
    <div class="bg-color-black">
      <div class="d-flex">
        <span class="fs-xl text">课程成交数量排序</span>
      </div>
      <div class="d-flex body-box" v-if="isRresh">
        <dv-scroll-board class="dv-scr-board" :config="config" />
      </div>
    </div>
  </div>
</template>

<script>
import { getGoods } from '../../../request/api'

export default {
  data () {
    return {
      config: {
        header: ['课程名', '成交', '校区'],
        data: [],
        rowNum: 7, //表格行数
        headerHeight: 35,
        headerBGC: '#0f1325', //表头
        oddRowBGC: '#0f1325', //奇数行
        evenRowBGC: '#171c33', //偶数行
        index: true,
        columnWidth: [43, 110, 50, 90],
        align: ['center']
      },
      timing: null,
      isRresh: true
    }
  },
  methods: {
    changeTiming () {
      this.changeNumber()
      // 十分钟一刷新
      this.timing = setInterval(() => {
        this.changeNumber()
      }, 600000)
    },
    async changeNumber () {
      const res = await getGoods()
      if (res.status !== 200 || res.data.code !== '1000') this.$message.error(res.data.msg)
      this.config.data = (res.data.data)
      this.update()
    },
    update () {
      this.isRresh = false
      this.$nextTick(() => {
        this.isRresh = true
      })
    }
  },
  mounted () {
    this.changeTiming()
  },
  beforeDestroy () {
    clearInterval(this.timing)
    this.timing = null
  }
}
</script>

<style lang="less" scoped>
.d-flex {
  display: flex;
}
.fs-xl {
  width: 100%;
  font-size: 8px;
}
.centerRight1 {
  padding: 16px;
  padding-top: 20px;
  height: 410px;
  width: 300px;
  border-radius: 5px;
  .bg-color-black {
    height: 410px;
    border-radius: 10px;
    background-color: #0f1325;
    width: 100%;
    .text {
      color: #c3cbde;
    }
    .body-box {
      border-radius: 10px;
      overflow: hidden;
      .dv-scr-board {
        width: 410px;
        height: 340px;
      }
    }
  }
}
</style>