<template>
  <div class="bottomLeft">
    <div class="bg-color-black">
      <div class="d-flex">
        <span class="fs-xl text">定单来源地统计</span>
      </div>
      <div>
        <bottomLeftChart :cdata="cdata" />
      </div>
    </div>
  </div>
</template>

<script>
import bottomLeftChart from '../components/bottom/bottomLeftChart'
import { getAreaAll } from '../../../request/api'

export default {
  components: {
    bottomLeftChart
  },
  data () {
    return {
      cdata: {},
      timing: null
    }
  },
  methods: {
    async changeNumber () {
      const res = await getAreaAll()
      if (res.status !== 200 || res.data.code !== '1000') return this.$message.error(res.data.msg)
      this.cdata = (res.data.data)
    },
    changeTiming () {
      this.changeNumber()
      // 十分钟一刷新
      this.timing = setInterval(() => {
        this.changeNumber()
      }, 600000)
    }
  },
  mounted () {
    this.changeTiming()
  },
  // 销毁前调用
  beforeDestroy () {
    clearInterval(this.timing)
    this.timing = null
  }
}
</script>

<style lang="less" scoped>
.d-flex {
  display: flex;
}
.fs-xl {
  width: 100%;
  font-size: 8px;
}
.bottomLeft {
  padding: 20px 16px;
  height: 520px;
  width: 100%;
  border-radius: 5px;
  .bg-color-black {
    height: 520px;
    width: 96%;
    border-radius: 10px;
    background-color: #0f1325;
    .text {
      color: #c3cbde;
    }
  }
}
</style>