<template>
  <div>
    <chart :cdata="cdata" />
  </div>
</template>

<script>
import chart from './chart.vue'

export default {
  components: {
    chart
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {

    }
  }
}
</script>

<style>

</style>