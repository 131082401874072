<template>
  <div class="centerLeft1">
    <div class="bg-color-black">
      <div class="d-flex">
        <span class="text fs-xl">个人业绩统计</span>
        <dv-decoration-3 class="dv-dec-3" />
      </div>
      <div class="d-flex">
        <centerLeft1Chart :cdata="cdata" />
      </div>
      <!-- 四个主要的数据 -->
      <div class="bottom-data" v-if="isRresh">
        <div class="item-box" v-for="(item, index) in numberData" :key="index">
          <div class="d-flex">
            <span class="coin">{{index === 1 || index === 3 ? '' : '￥'}}</span>
            <dv-digital-flop class="dv-digital-flop" :config="item.number" />
          </div>
          <p class="text" style="text-align: center;">
            {{ item.text }}
            <span class="colorYellow">{{index === 0 || index === 2 ? '（元）' : '（单）'}}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import centerLeft1Chart from './centerLeft/centerLeft1Chart'
import { getPersonal, getOrderAll } from '../../../request/api'

export default {
  components: {
    centerLeft1Chart
  },
  data () {
    return {
      numberData: [
        {
          number: {
            number: [],
            toFixed: 2,
            textAlign: 'left',
            content: '{nt}',
            style: {
              fontSize: 24
            }
          },
          text: '总订单金额'
        },
        {
          number: {
            number: [],
            toFixed: 0,
            textAlign: 'left',
            content: '{nt}',
            style: {
              fontSize: 24
            }
          },
          text: '总订单数量'
        },
        {
          number: {
            number: [],
            toFixed: 2,
            textAlign: 'left',
            content: '{nt}',
            style: {
              fontSize: 24
            }
          },
          text: '今日订单金额'
        },
        {
          number: {
            number: [],
            toFixed: 0,
            textAlign: 'left',
            content: '{nt}',
            style: {
              fontSize: 24
            }
          },
          text: '今日订单数量'
        }
      ],
      timing: null,
      cdata: {},
      isRresh: true
    }
  },
  methods: {
    changeTiming () {
      this.changeNumber()
      // 十分钟一刷新
      this.timing = setInterval(() => {
        this.changeNumber()
      }, 600000)
    },
    async changeNumber () {
      const res = await getPersonal()
      if (res.status !== 200 || res.data.code !== '1000') this.$message.error(res.data.msg)
      this.cdata = res.data.data

      const ret = await getOrderAll()
      if (ret.status !== 200 || ret.data.code !== '1000') this.$message.error(ret.data.msg)
      const nuData = ret.data.data
      this.numberData[0].number.number[0] = (nuData.sum_all)
      this.numberData[1].number.number[0] = (nuData.count_all)
      this.numberData[2].number.number[0] = (nuData.sum)
      this.numberData[3].number.number[0] = (nuData.count)
      this.update()
    },
    update () {
      this.isRresh = false
      this.$nextTick(() => {
        this.isRresh = true
      })
    }
  },
  mounted () {
    this.changeTiming()
  },
  // 销毁前调用
  beforeDestroy () {
    clearInterval(this.timing)
    this.timing = null
  }
}
</script>

<style lang="less" scoped>
.d-flex {
  display: flex;
}
.fs-xl {
  width: 100%;
  font-size: 8px;
}
.centerLeft1 {
  padding: 16px;
  height: 410px;
  width: 300px;
  border-radius: 10px;
  .bg-color-black {
    height: 410px;
    border-radius: 10px;
    background-color: #0f1325;
    .text {
      color: #c3cbde;
    }
    .dv-dec-3 {
      position: relative;
      width: 100px;
      height: 20px;
      top: -3px;
    }
    .bottom-data {
      // margin-top: 10px;
      .item-box {
        & > div {
          padding-right: 5px;
        }
        font-size: 14px;
        float: right;
        position: relative;
        width: 50%;
        color: #d3d6dd;
        .coin {
          position: relative;
          top: 6px;
          font-size: 20px;
          color: #ffc107;
        }
        .dv-digital-flop {
          width: 120px;
          height: 30px;
        }
        .colorYellow {
          color: yellowgreen;
        }
        p {
          text-align: center;
        }
      }
    }
  }
}
</style>