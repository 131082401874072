<template>
  <div class="center">
    <div class="up" v-if="isRresh">
      <div class="bg-color-black item" v-for="item in titleItem" :key="item.title">
        <p class="fs-xl colorBlue fw-b">{{item.title}}</p>
        <div>
          <dv-digital-flop class="dv-dig-flop" :config="item.number" />
        </div>
      </div>
    </div>
    <div class="down" v-if="isRresh">
      <div class="ranking bg-color-black">
        <span class="fs-xl text">分销员成交额排序（月）</span>
        <dv-scroll-ranking-board class="dv-scr-rank-board" :config="ranking" />
      </div>
      <div class="percent">
        <div class="item bg-color-black">
          <span>本月订单增长率</span>
          <Center :id="rate[0].id" :tips="rate[0].tips" :colorObj="rate[0].colorData" />
        </div>
        <div class="item bg-color-black">
          <span>本周订单增长率</span>
          <Center :id="rate[1].id" :tips="rate[1].tips" :colorObj="rate[1].colorData" />
        </div>
        <div class="water">
          <dv-water-level-pond class="dv-wa-le-po" :config="water" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Center from './center/index.vue'
import { getChainOrderAll, getUserRank, getCompute } from '../../../request/api'

export default {
  components: {
    Center
  },
  data () {
    return {
      titleItem: [
        {
          title: '今年累计订单数量',
          number: {
            number: [],
            toFixed: 0,
            textAlign: 'left',
            content: '{nt}',
            style: {
              fontSize: 26
            }
          }
        },
        {
          title: '本月累计订单数量',
          number: {
            number: [],
            toFixed: 0,
            textAlign: 'left',
            content: '{nt}',
            style: {
              fontSize: 26
            }
          }
        },
        {
          title: '今日累计订单数量',
          number: {
            number: [],
            toFixed: 0,
            textAlign: 'left',
            content: '{nt}',
            style: {
              fontSize: 26
            }
          }
        },
        {
          title: '今年累计订单金额',
          number: {
            number: [],
            toFixed: 2,
            textAlign: 'left',
            content: '{nt}',
            style: {
              fontSize: 26
            }
          }
        },
        {
          title: '本月累计订单金额',
          number: {
            number: [],
            toFixed: 2,
            textAlign: 'left',
            content: '{nt}',
            style: {
              fontSize: 26
            }
          }
        },
        {
          title: '今日累计订单金额',
          number: {
            number: [],
            toFixed: 2,
            textAlign: 'left',
            content: '{nt}',
            style: {
              fontSize: 26
            }
          }
        }
      ],
      ranking: {
        data: [],
        carousel: 'single',
        unit: '元'
      },
      water: {
        data: [],
        shape: 'roundRect',
        formatter: '{value}%',
        waveNum: 4
      },
      // 通过率和达标率的组件复用数据
      rate: [
        {
          id: 'centerRate1',
          tips: 0,
          colorData: {
            textStyle: '#3fc0fb',
            series: {
              color: ['#00bcd44a', 'transparent'],
              dataColor: {
                normal: '#03a9f4',
                shadowColor: '#97e2f5'
              }
            }
          }
        },
        {
          id: 'centerRate2',
          tips: 0,
          colorData: {
            textStyle: '#67e0e3',
            series: {
              color: ['#faf3a378', 'transparent'],
              dataColor: {
                normal: '#ff9800',
                shadowColor: '#fcebad'
              }
            }
          }
        }
      ],
      timing: null,
      isRresh: true
    }
  },
  methods: {
    async getOrder () {
      const res = await getChainOrderAll()
      if (res.status !== 200 || res.data.code !== '1000') this.$message.error(res.data.msg)
      const nuData = res.data.data
      this.titleItem[0].number.number[0] = (nuData.order_year_count)
      this.titleItem[1].number.number[0] = (nuData.order_month_count)
      this.titleItem[2].number.number[0] = (nuData.order_day_count)
      this.titleItem[3].number.number[0] = (nuData.order_year_sum)
      this.titleItem[4].number.number[0] = (nuData.order_month_sum)
      this.titleItem[5].number.number[0] = (nuData.order_day_sum)
      this.update()

      const ret = await getUserRank()
      if (ret.status !== 200 || ret.data.code !== '1000') this.$message.error(ret.data.msg)
      this.ranking.data = ret.data.data

      const pro = await getCompute()
      if (ret.status !== 200 || ret.data.code !== '1000') this.$message.error(ret.data.msg)
      const pro_all = (pro.data.data)
      this.water.data[0] = pro_all.day_pro
      this.rate[0].tips = pro_all.month_pro
      this.rate[1].tips = pro_all.week_pro
      this.update()
    },
    changeTiming () {
      this.getOrder()
      // 十分钟刷新一次
      this.timing = setInterval(() => {
        this.getOrder()
      }, 600000)
    },
    update () {
      this.isRresh = false
      this.$nextTick(() => {
        this.isRresh = true
      })
    }
  },
  mounted () {
    this.changeTiming()
  },
  // 销毁前调用
  beforeDestroy () {
    clearInterval(this.timing)
    this.timing = null
  }
}
</script>

<style lang="less" scoped>
.bg-color-black {
  border-radius: 5px;
}
.colorBlue {
  color: #257dff !important;

  &:hover {
    color: #257dff !important;
  }
}
.fw-b {
  font-weight: bold;
}
.fs-xl {
  width: 100%;
  font-size: 8px;
}
.center {
  display: flex;
  flex-direction: column;
  .up {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .item {
      border-radius: 6px;
      padding-top: 8px;
      margin-top: 8px;
      width: 32%;
      height: 70px;
      background-color: #0f1325;
      .dv-dig-flop {
        width: 140px;
        height: 28px;
      }
    }
  }
  .down {
    padding: 6px 4px;
    padding-bottom: 0;
    width: 100%;
    display: flex;
    height: 255px;
    justify-content: space-between;
    .bg-color-black {
      border-radius: 5px;
    }
    .ranking {
      padding: 10px;
      width: 59%;
      background-color: #0f1325;
      .text {
        color: #c3cbde;
      }
      .dv-scr-rank-board {
        height: 225px;
      }
    }
    .percent {
      width: 40%;
      display: flex;
      flex-wrap: wrap;
      .item {
        width: 50%;
        height: 120px;
        span {
          margin-top: 8px;
          font-size: 8px;
          display: flex;
          justify-content: center;
        }
      }
      .water {
        width: 100%;
        .dv-wa-le-po {
          height: 120px;
        }
      }
    }
  }
}
</style>