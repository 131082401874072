<template>
  <div>
    <Echart
      :options="options"
      id="centreLeft1Chart"
      height="240px"
      width="300px"
    ></Echart>
  </div>
</template>

<script>
import Echart from '../../../../common/echart'

export default {
  components: {
    Echart
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      options: {}
    }
  },
  watch: {
    cdata: {
      handler (newData) {
        this.options = {
          // 全局调色盘
          color: [
            "#37a2da",
            "#32c5e9",
            "#9fe6b8",
            "#ffdb5c",
            "#ff9f7f",
            "#fb7293",
            "#e7bcf3",
            "#8378ea"
          ],
          tooltip: {
            trigger: "item",
            formatter: "{a} <br/>{b} : {c} ({d}%)"
          },
          toolbox: {
            show: true
          },
          calculable: true,
          legend: {
            orient: "horizontal",
            icon: "circle",
            bottom: 0,
            x: "center",
            data: newData.xData,
            textStyle: {
              color: "#fff"
            }
          },
          series: [
            {
              name: "本周售出",
              type: "pie",
              radius: [10, 70],
              // 设置为南丁格尔图
              roseType: "area",
              center: ["50%", "40%"],
              data: newData.seriesData
            }
          ]
        }
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style>

</style>