<template>
  <div class="index" ref="appRef">
    <div class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <!-- 第一行 -->
        <div class="d-flex">
          <dv-decoration-10 class="dv-dec-10" />
          <div class="d-flex">
            <dv-decoration-8 class="dv-dec-8" :color="decorationColor" />
            <div class="title">
              <span class="title-text">数据可视化平台</span>
              <dv-decoration-6
                class="dv-dec-6"
                :reverse="true"
                :color="['#50e3c2', '#67a1e5']"
              />
            </div>
            <dv-decoration-8 class="dv-dec-8" :reverse="true" :color="decorationColor" />
          </div>
          <dv-decoration-10 class="dv-dec-10" />
        </div>
        <!-- 第二行 -->
        <div class="d-flex jc-between">
          <div class="d-flex aside-width">
            <div class="react-left react-l-s">
              <span class="react-left"></span>
              <span class="text">个人业绩统计</span>
            </div>
            <div class="react-left">
              <span class="text"></span>
            </div>
          </div>
          <div class="d-flex aside-width">
            <div class="bg-color-blue react-right">
              <span class="text fw-b"></span>
            </div>
            <div class="react-right react-l-s">
              <span class="react-after"></span>
              <span class="text">{{ dateYear }} {{ dateWeek }} {{ dateDay }}</span>
            </div>
          </div>
        </div>
        <div class="body-box">
          <!-- 第三行数据 -->
          <div class="content-box">
            <div>
              <dv-border-box-12>
                <centerLeft1 />
              </dv-border-box-12>
            </div>
            <div></div>
            <!-- 右侧内容 -->
            <div>
              <centerRight2 />
            </div>
            <div></div>
            <div>
              <dv-border-box-13>
                <centerRight />
              </dv-border-box-13>
            </div>
          </div>
          <!-- 第四行数据 -->
          <div class="d-flex aside-width">
            <div class="react-left react-l-s">
              <span class="react-left"></span>
              <span class="text">校区业绩统计</span>
            </div>
            <div class="react-left">
              <span class="text"></span>
            </div>
          </div>
          <div class="content-center">
            <div>
              <center />
            </div>
            <div>
              <dv-border-box-13>
                <!-- 自己与上级机构的课程排序 -->
                <centerRight1 />
              </dv-border-box-13>
            </div>
          </div>
          <div class="bottom-box">
            <dv-border-box-13>
              <bottomLeft />
            </dv-border-box-13>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatTime } from '../../assets/js/index'
import centerLeft1 from './components/centerLeft1.vue'
import center from './components/center.vue'
import centerRight2 from './components/centerRight2.vue'
import centerRight1 from './components/centerRight1.vue'
import centerRight from './components/centerRight.vue'
import bottomLeft from './components/bottomLeft.vue'
// import bottomRight from './components/bottomRight.vue'

export default {
  components: {
    centerLeft1,
    center,
    centerRight2,
    centerRight1,
    bottomLeft,
    centerRight
  },
  data () {
    return {
      loading: true,
      decorationColor: ['#568aea', '#000000'],
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
      timing: null
    }
  },
  methods: {
    cancelLoading () {
      // 只执行一次
      setTimeout(() => {
        this.loading = false
      }, 500)
    },
    timeFn () {
      // 经过固定时间间隔重复执行
      this.timing = setInterval(() => {
        this.dateDay = formatTime(new Date(), 'HH: mm: ss')
        this.dateYear = formatTime(new Date(), 'yyyy-MM-dd')
        this.dateWeek = this.weekday[new Date().getDay()]
      }, 1000)
    }
  },
  mounted () {
    this.timeFn()
    this.cancelLoading()
  },
  // 销毁前调用
  beforeDestroy () {
    clearInterval(this.timing)
  }
}
</script>

<style lang="less" scoped>
.d-flex {
  display: flex;
}
.bg-color-blue {
  background-color: #1a5cd7;
}
.fw-b {
  font-weight: bold;
}
.index {
  .bg {
    color: #d3d6dd;
    height: 1800px;
    padding: 16px 16px 0 16px;
    background-image: url("../../assets/image/pageBg.png");
    background-size: cover;
    /* 设置背景图片位置 left right top bottom center 也可以设置坐标像素 */
    background-position: center center;
    overflow: hidden;
    .host-body {
      .body-box {
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        .content-box {
          display: grid;
          grid-template-columns: 3fr 2fr 3fr 2fr 3fr;
        }
        .content-center {
          display: grid;
          grid-template-columns: 10fr 3fr;
        }
        .bottom-box {
          margin-top: 10px;
        }
      }
      .jc-between {
        justify-content: space-between;
      }
      .aside-width {
        width: 40%;
        .react-left {
          font-size: 18px;
          width: 300px;
          height: 50px;
          line-height: 50px;
          text-align: center;
          transform: skewX(45deg);
          background-color: #0f1325;
          &.react-l-s {
            width: 500px;
            text-align: left;
          }
          .react-left {
            position: absolute;
            left: -25px;
            top: 0;
            height: 50px;
            width: 50px;
            background-color: #0f1325;
            transform: skewX(-45deg);
          }
          .text {
            display: inline-block;
            transform: skewX(-45deg);
          }
        }
        .react-right {
          font-size: 18px;
          width: 300px;
          height: 50px;
          line-height: 50px;
          text-align: center;
          transform: skewX(-45deg);
          .text {
            display: inline-block;
            transform: skewX(45deg);
          }
          &.react-l-s {
            text-align: right;
            width: 500px;
            background-color: #0f1325;
          }
          .react-after {
            position: absolute;
            right: -25px;
            top: 0;
            height: 50px;
            width: 50px;
            background-color: #0f1325;
            transform: skewX(45deg);
          }
        }
      }
      .dv-dec-10 {
        width: 33.3%;
        height: 5px;
      }
      .dv-dec-8 {
        width: 200px;
        height: 50px;
      }
      .title {
        position: relative;
        width: 500px;
        text-align: center;
        background-size: cover;
        background-repeat: no-repeat;
        .title-text {
          font-size: 24px;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translate(-50%);
        }
        .dv-dec-6 {
          position: absolute;
          bottom: -30px;
          left: 50%;
          width: 250px;
          height: 8px;
          transform: translate(-50%);
        }
      }
    }
  }
}
</style>